import { Component, OnInit } from '@angular/core';
declare var jQuery: any;

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    jQuery(document).ready(function () {
      // jQuery(".center").not('.slick-initialized').slick({
      //   autoplay: true,
      //   centerMode: true,
      //   arrows: true,
      //   slidesToShow: 1,
      //   slidesToScroll: 1,
      //   responsive: [
      //     {
      //       breakpoint: 1024,
      //       settings: "unslick"
      //     },
      //     {
      //       breakpoint: 600,
      //       settings: "unslick"
      //     },
      //     {
      //       breakpoint: 481,
      //       settings: {
      //         slidesToShow: 1,
      //         slidesToScroll: 1
      //       }
      //     }
      //   ]
      // });

      // jQuery('.center').not('.slick-initialized').slick({
      //   centerMode: true,
      //   centerPadding: '60px',
      //   slidesToShow: 3,
      //   responsive: [
      //     {
      //       breakpoint: 768,
      //       settings: {
      //         arrows: false,
      //         centerMode: true,
      //         centerPadding: '40px',
      //         slidesToShow: 3
      //       }
      //     },
      //     {
      //       breakpoint: 480,
      //       settings: {
      //         arrows: false,
      //         centerMode: true,
      //         centerPadding: '40px',
      //         slidesToShow: 1
      //       }
      //     }
      //   ]
      // });
    })
  }

  loadCarousel() {



  }
}